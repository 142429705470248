import React from 'react';
import PropTypes from 'prop-types';

import autobind from 'common/decorators/autobind.js';

export default class ReCaptcha extends React.Component {
    static propTypes = {
        action: PropTypes.string.isRequired,
        setCaptchaToken: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.siteKey = document.body.dataset.reCaptchaKey;
        this.startTime = new Date();
        this.timeout = 110; // 2 minutes minus 10 seconds
    }

    componentDidMount() {
        if (!this.siteKey) {
            throw new Error(
                `ReCaptcha key required on ${window.location.href}`,
            );
        }
        const p = new Promise((resolve) => (window.initCaptcha = resolve));
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.google.com/recaptcha/api.js?render=${this.siteKey}&onload=initCaptcha`;
        script.type = 'text/javascript';
        this.script = script;
        document.head.appendChild(script);
        p.then(this.setCaptcha);
    }

    componentWillUnmount() {
        document.head.removeChild(this.script);
    }

    @autobind
    setCaptcha() {
        // grecaptcha loaded by the script
        window.grecaptcha.ready(() =>
            window.grecaptcha
                .execute(this.siteKey, {action: this.props.action})
                .then((captchaToken) =>
                    this.props.setCaptchaToken(captchaToken),
                ),
        );
    }

    shouldRegenerateRecaptchaToken() {
        if ((new Date() - this.startTime) / 1000 > this.timeout) {
            return true;
        }
        return false;
    }

    @autobind
    async renewRecaptchaToken() {
        return await window.grecaptcha.execute(this.siteKey, {
            action: this.props.action,
        });
    }

    render() {
        return null;
    }
}
